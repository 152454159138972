import React from 'react'
import Lodash from 'lodash'
import Link from 'gatsby-link'
import queryString from 'query-string'

import { OrderStatusMap } from '../constants/orderStatusMap'

import Layout from '../components/Layout'
import { getFirebase } from '../services/firebase'

class OrderPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      statusFilter: queryString.parse(props.location.search).statusFilter || ""
    };

    this.beginPreparing = this.beginPreparing.bind(this);
    this.readyForPickup = this.readyForPickup.bind(this);
    this.pickUp = this.pickUp.bind(this);
  }

  componentDidMount() {
    const lazyApp = import('firebase/app')
    const lazyDatabase = import('firebase/firestore')

    Promise.all([lazyApp,lazyDatabase]).then(([firebase]) => {
      this.database = getFirebase(firebase).firestore()
      // do something with `database` here,
      // or store it as an instance variable or in state
      // to do stuff with it later
      if(this.state.statusFilter) {
        this.database.collection("orders")
          .where("status", "==", this.state.statusFilter)
          .onSnapshot((snapshot) => {
          this.setState({ orders: snapshot.docs.map((doc) => Object.assign({}, doc.data(), { id: doc.id }) ) })
        });
      } else {
        this.database.collection("orders")
          .onSnapshot((snapshot) => {
          this.setState({ orders: snapshot.docs.map((doc) => Object.assign({}, doc.data(), { id: doc.id }) ) })
        });
      }
    })
  }

  beginPreparing(orderID) {
    this.database.doc("orders/" + orderID).update({status: "Being Prepared"});
  }

  readyForPickup(orderID) {
    this.database.doc("orders/" + orderID).update({status: "Ready For Pickup"});
  }

  pickUp(orderID) {
    this.database.doc("orders/" + orderID).update({status: "Picked Up"});
  }

  orderBackgroundColor(status) {
    switch(status) {
      case "Order Placed":
        return 'rgba(210, 204, 3, 0.4)';
      case "Being Prepared":
        return 'rgba(199, 103, 3, 0.25)';
      case "Ready For Pickup":
        return "#00800040";
      default:
        return "#eee"
    }
  }

  render() {
    const { orders, statusFilter } = this.state;
    console.log(statusFilter);
    let orderToBeCompleted = orders.filter((o)=> o.status !== "Picked Up").length;

    return (
      <Layout>
        <section
          className="section section--gradient"
          style={{
            color: 'black'
          }}
        >
          <div className="container">
            <div className="columns">
              <div className="column">
                <div className="section">
                  <h2 className="is-size-3 has-text-weight-bold is-bold-light">
                    {orderToBeCompleted} Orders
                  </h2>
                  <div
                    style={{
                      marginTop: "20px",
                      marginBottom: "30px"
                    }}
                  >
                    Filter By:
                    <a
                      style={{ marginLeft: "15px" }}
                      className={statusFilter === "" ? "selected" : ""}
                      href={`/orders-dashboard`}
                    >
                      All
                    </a>
                    {Object.entries(OrderStatusMap).map(([key, value]) => {
                      return (
                        <a
                          style={{ marginLeft: "15px" }}
                          className={statusFilter === key ? "selected" : ""}
                          href={`/orders-dashboard?statusFilter=${key}`}
                        >
                          {key}
                        </a>
                      )
                    })}
                  </div>
                  <div className="columns is-multiline">
                    {
                      orders.sort(function(a, b) {
                        return OrderStatusMap[a.status] - OrderStatusMap[b.status];
                      }).sort(function(a, b) {
                        if(a["createdAt"] && b["createdAt"]) {
                          return b["createdAt"].seconds - a["createdAt"].seconds;
                        } else {
                          return 11;
                        }
                      }).map((order, index)=>{
                        return(
                          <div class="column is-one-third" id={index}
                            style={{
                              padding: '10px',
                              minWidth: "33%"
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: this.orderBackgroundColor(order.status),
                                padding: '10px',
                                height: "280px"
                              }}
                            >
                              <ul
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "100%"
                                }}
                              >
                                <li>
                                  Name :&nbsp;
                                  <span style={{fontWeight: '700'}}>{order.name.toString()}</span>
                                </li>
                                <li>
                                  Burger Type:&nbsp;
                                  <span style={{fontWeight: '700'}}>{order.numberOfPattys.toString()}</span>
                                </li>
                                {
                                  Object.entries(order).map(([key, value])=> {
                                    if (key != "numberOfPattys" && key != "name" && key != "id" && key != "userId" && value != "false" && key != "status" && key != "email" && key != "createdAt") {
                                      return (<li>
                                        {Lodash.startCase(key)}:&nbsp;
                                        <span style={{fontWeight: '700'}}>{value.toString()}</span>
                                      </li>)
                                    }
                                  }
                                )}
                                <li style={{
                                  marginTop: "auto"
                                }}>
                                  {order.status === "Order Placed" &&
                                    <button className="btn dashboard-button" onClick={event=>this.beginPreparing(order.id,event)}>Begin Preparation</button>
                                  }
                                  {order.status === "Being Prepared" &&
                                    <button className="btn dashboard-button" onClick={event=>this.readyForPickup(order.id,event)}>Ready For Pickup</button>
                                  }
                                  {order.status === "Ready For Pickup" &&
                                    <button className="btn dashboard-button" onClick={event=>this.pickUp(order.id,event)}>Pick Up</button>
                                  }
                                </li>
                                {order.createdAt &&
                                  <li
                                    style={{
                                      fontSize: "0.5rem",
                                      marginTop: "10px"
                                    }}
                                  >
                                  Order Placed:&nbsp;
                                    <span style={{fontWeight: '700'}}>{order.createdAt.toDate().toLocaleString()}</span>
                                  </li>
                                }
                              </ul>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default OrderPage
